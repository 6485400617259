<template>
    <el-container class="body">
        <pub-header  class="el-menu-header"></pub-header>
        <el-container>
            <div class="el-main-box" ref="el_main_box">
                <el-empty v-if="none_arg" description="暂无数据"></el-empty>
                <div style="position: absolute; right: 1%; top: 2%; width: 400px;">
                    <el-select v-model="value" clearable  placeholder="请选择" @change="changeSelectHotel" style="margin-right: 10%;">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <el-table
                    :data="tableData"
                    border
                    style="width: 90%; position: absolute; left: 5%; top: 10%">
                    <el-table-column
                    fixed
                    prop="insertAt"
                    label="时间">
                    </el-table-column>
                    <el-table-column
                    prop="firstName"
                    label="姓">
                    </el-table-column>
                    <el-table-column
                    prop="lastName"
                    label="名">
                    </el-table-column>
                    <el-table-column
                    prop="email"
                    label="Email">
                    </el-table-column>
                    <el-table-column
                    prop="phoneNumber"
                    label="电话">
                    </el-table-column>
                    <el-table-column
                    prop="cardNumberElement"
                    label="银行卡号">
                    </el-table-column>
                    <el-table-column
                    prop="cardExpirationYearElement"
                    label="有效年">
                    </el-table-column>
                    <el-table-column
                    prop="cardExpirationMonthElement"
                    label="有效月">
                    </el-table-column>
                    <el-table-column
                    prop="checkCode"
                    label="确定号">
                    </el-table-column>
                    <el-table-column
                    fixed="right"
                    prop="state"
                    label="状态">
                    </el-table-column>
                </el-table>
            </div>
        </el-container>
    </el-container>
</template>

<script>
import pubHeader from "./header.vue"
export default {
    data(){
        return{
            activeIndex: "1",
            none_arg: false,
            tableData: [],
            options: [
                {value: "成功", label: "预约成功"},
                {value: "失败", label: "预约失败"},
                {value: "取消", label: "预约取消"},
            ],
            value: ""
        }
    },
    components: {pubHeader},
    mounted: function(){
        this.getRegistrationList();
    },
    methods: {
        getRegistrationList(val){
            this.$axios.get("/api/hotel/getRegis", {
                "params": {"state": val}
            }).then(
                res => {
                    if (res.data.state == 200){
                        this.$message.success("成功");
                        this.tableData = res.data.hits
                        
                    }else{
                        this.$message.success(res.data.msg);
                    }
                    
                }
            )
        },
        changeSelectHotel(val){
            console.log(val);
            this.$forceUpdate();
            this.getRegistrationList(val)
        }
    }
}
</script>

<style scoped>
    .body{
        height: 100%;
        background: #f7f8f9;
    }
    .el-menu-header{
        width: 100%;
    }
    .el-main-box{
        width: 80%;
        height: 85%;
        position: absolute;
        left: 10%;
        top: 8%;
        background: white;
    }
</style>
